<template>
  <div>
    <div>
      <div class="card-title">{{ $t("role.planning.calendar.title") }}</div>

      <div class="">
        <button
          v-if="!loading"
          class="btn btn-sm btn-primary"
          @click="getDates()"
        >
          {{ $t("role.planning.calendar.refresh") }}
        </button>
        <div v-else class="loading">
          <CSpinner color="primary" size="sm" />
          {{ $t("common.general.loading") }}
        </div>
      </div>
    </div>
    <calendar-view
      id="calendar"
      :events="events"
      enable-drag-drop_
      :show-date="showDate"
      :displayPeriodUom="displayPeriod"
      currentPeriodLabel="icons"
      :class="themeClasses"
      @click-date="onClickDay"
      @click-event="onClickEvent"
      @drop-on-date_="onDrop"
      @show-date-change="setShowDate"
      ref="calendarView"
    >
      <calendar-view-header
        slot="header"
        slot-scope="{ headerProps }"
        :header-props="headerProps"
        @input="setShowDate"
      />
    </calendar-view>
    <!--
    <CForm inline>
      <CSelect
        :options="[
          $t('role.logistics.Planning.calendar.viewMonth'),
          $t('role.logistics.Planning.calendar.viewWeek'),
        ]"
        :value.sync="displayPeriod"
        :label="$t('role.logistics.Planning.calendar.viewLabel')"
        add-wrapper-classes="ml-2"
      />
      <h4 class="ml-auto my-auto">
        <CBadge v-if="message" color="primary">{{ message }}</CBadge>
      </h4>
    </CForm>
    -->
    <CModal
      :size="modal.size"
      scrollable
      :show="modal.show"
      :closeOnBackdrop="false"
      :centered="true"
    >
      <template #header>
        <h5 class="modal-title" v-html="modal.title"></h5>
      </template>

      <div v-if="modal.is_close">
        <table class="w-100">
          <tbody>
            <tr>
              <th>{{ $t("role.logistics.Planning.calendar.fromDate") }}:</th>
              <td>{{ modal.data.date_start | formatDate }}</td>
            </tr>
            <tr>
              <th>{{ $t("role.logistics.Planning.calendar.toDate") }}:</th>
              <td>{{ modal.data.date_end | formatDate }}</td>
            </tr>
            <tr>
              <th>{{ $t("role.logistics.Planning.calendar.duration") }}:</th>
              <td>
                {{ modal.data.duration }}
                {{ $t("role.logistics.Planning.calendar.days") }}
              </td>
            </tr>
          </tbody>
          <tbody v-if="modal.data.role">
            <tr>
              <th>{{ $t("role.logistics.Planning.calendar.phone") }}:</th>
              <td>{{ modal.data.role.tel }}</td>
            </tr>
            <tr>
              <th>url:</th>
              <td></td>
            </tr>
            <tr>
              <th>
                {{ $t("role.logistics.Planning.calendar.contactInformation") }}:
              </th>
              <td v-html="modal.data.role.contacts"></td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="modal.is_ddmi">
        <table class="w-100">
          <tbody>
            <tr>
              <th>{{ $t("role.logistics.Planning.calendar.day") }}:</th>
              <td>{{ modal.data.date | formatDate }}</td>
            </tr>
            <tr>
              <th>{{ $t("role.logistics.Planning.calendar.time") }}:</th>
              <td>{{ modal.data.date | formatTime }}</td>
            </tr>
            <tr v-if="modal.data.start">
              <th>
                {{
                  $t("role.logistics.Planning.calendar.deliveryStartingFrom")
                }}:
              </th>
              <td>{{ modal.data.start | formatTime }}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div v-if="modal.is_ddmp">
        <table class="w-100">
          <tbody>
            <tr>
              <th>
                {{ $t("role.logistics.Planning.calendar.recievingOrders") }}:
              </th>
              <td>
                {{ modal.data.time_limit | formatDate }}
                {{ $t("role.logistics.Planning.calendar.at") }}
                {{ modal.data.time_limit | formatTime }}
              </td>
            </tr>
            <tr>
              <th>
                {{ $t("role.logistics.Planning.calendar.deliveringOrders") }}:
              </th>
              <td>
                {{ modal.data.delivery_date | formatDate }}
                {{ $t("role.logistics.Planning.calendar.at") }}
                {{ modal.data.delivery_date | formatTime }}
              </td>
            </tr>
            <tr>
              <th>{{ $t("role.logistics.Planning.calendar.wh") }}:</th>
              <td>{{ modal.data.wh }}</td>
            </tr>
          </tbody>
        </table>
        <hr />
        <div v-html="modal.data.delivery_info"></div>
      </div>

      <div v-if="modal.is_round">
        <div v-if="modal.is_loading">
          <div class="loading text-center">
            <CSpinner color="primary" size="lg" />
          </div>
        </div>
        <table v-else class="w-100 timeline">
          <tbody>
            <tr v-for="date in modal.data.dates" :key="date.id">
              <th class="w-25">
                <span class="bullet" :class="getBulletClass(date)">
                  {{ date.date | formatDate }}
                  {{ $t("role.logistics.Planning.calendar.at") }}
                  {{ date.date | formatTime }}
                </span>
              </th>
              <td>
                <CCard>
                  <CCardHeader>
                    <h4>{{ date.title }}</h4>
                  </CCardHeader>
                  <CCardBody v-if="date.details">
                    <ul>
                      <li
                        v-for="detail in date.details"
                        :key="detail.id"
                        :class="{ disabled_ddm: !detail.active }"
                      >
                        <span v-if="detail.direct_delivery">
                          <b>{{
                            $t(
                              "role.logistics.Planning.calendar.directDelivery"
                            )
                          }}</b>
                          -
                        </span>
                        <span>
                          {{ detail.name }}
                        </span>
                      </li>
                    </ul>
                  </CCardBody>
                </CCard>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <template #footer>
        <CButton
          color="primary"
          variant="outline"
          @click.prevent="closeModal"
          >{{ $t("common.editor.button.close") }}</CButton
        >
      </template>
    </CModal>
  </div>
</template>
<style>
.cv-week {
  min-height: 15em !important;
}
.cv-event.danger {
  background-color: #e55353;
}
.cv-event.warning {
  background-color: #f9b115;
}
.cv-event.producer {
  background-color: #9ccc65;
}
.cv-event.producer_mg {
  background-color: #5e7c3b;
  color: white;
}
.cv-event.producer_ms {
  background-color: #779c4c;
}
.cv-event.island {
  background-color: #88cbd1;
}
.cv-event.island_mg {
  background-color: #5b878b;
  color: white;
}
.cv-event.island_ms {
  background-color: #88cbd1;
}
.disabled_ddm {
  text-decoration: line-through;
}
/* table.timeline {
} */
table.timeline th {
  background: linear-gradient(
    90deg,
    rgba(0, 0, 0, 0) calc(14px),
    rgb(167, 167, 167) calc(17px),
    rgb(167, 167, 167) calc(23px),
    rgba(0, 0, 0, 0) calc(25px)
  );
  vertical-align: top;
  padding: 4px;
}
.bullet {
  font-size: 1em;
}
.bullet_info:before {
  content: "";
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  background-color: #ffffff;
  vertical-align: middle;
  border: 2px solid rgb(167, 167, 167);
}
.bullet_producer:before {
  content: "";
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  background-color: #9ccc65;
  vertical-align: middle;
  border: 2px solid rgb(167, 167, 167);
}
.bullet_island:before {
  content: "";
  width: 32px;
  height: 32px;
  border-radius: 50%;
  margin-right: 10px;
  display: inline-block;
  background-color: #88cbd1;
  vertical-align: middle;
  border: 2px solid rgb(167, 167, 167);
}
</style>
<script>
import {
  CalendarView,
  CalendarViewHeader,
  CalendarMathMixin,
} from "vue-simple-calendar";
// import CalendarMathMixin from 'vue-simple-calendar/dist/calendar-math-mixin.js'
// The next two lines are processed by webpack. If you're using the component without webpack compilation,
// you should just create <link> elements for these as you would normally for CSS files. Both of these
// CSS files are optional, you can create your own theme if you prefer.
import "vue-simple-calendar/static/css/default.css";
// import "vue-simple-calendar/static/css/holidays-us.css";
import { GetDataManagerNew, ApiCall } from "../../../ds/index";
import { Query, DataManager } from "@syncfusion/ej2-data";
import { apiUrl } from "../../../http";
import DrfAdaptorNew from "../../../http/DrfAdaptorNew";
import moment from "moment";

const currDate = new Date();
function addDays(date, days) {
  var result = new Date(date);
  result.setDate(result.getDate() + days);
  return result;
}
export default {
  name: "Calendar",
  mixins: [CalendarMathMixin],
  data: function () {
    return {
      message: "",
      showDate: currDate,
      displayPeriod: "month",
      events: [],
      useDefaultTheme: true,
      useHolidayTheme: true,
      loading: false,
      modal: {
        size: null,
        show: false,
        loading: false,
        title: "",
        is_close: false,
        is_ddmp: false,
        is_ddmi: false,
        is_round: false,
        is_loading: false,
        data: null,
      },
    };
  },
  components: {
    CalendarView,
    CalendarViewHeader,
  },
  computed: {
    themeClasses() {
      return {
        "theme-default": this.useDefaultTheme,
        // "holiday-us-traditional": this.useHolidayTheme,
        // "holiday-us-official": this.useHolidayTheme,
      };
    },
  },
  created() {
    this.getDates();
  },
  filters: {
    formatDate: function (value) {
      if (value) {
        return moment(String(value)).locale("it").format("dddd DD MMMM YYYY");
      }
    },
    formatTime: function (value) {
      if (value) {
        return moment(String(value)).locale("it").format("LT");
      }
    },
  },
  methods: {
    closeModal() {
      this.modal.show = false;
    },
    getBulletClass(date) {
      return "bullet_" + date.color;
    },
    openModal(title, e_type, data) {
      this.modal.data = data;
      this.modal.title = title;
      this.modal.show = true;
      this.modal.is_close = false;
      this.modal.is_ddmp = false;
      this.modal.is_ddmi = false;
      this.modal.is_round = false;
      this.modal.is_loading = false;
      this.modal.size = null;
      if (e_type == "R") {
        this.modal.is_round = true;
        this.modal.size = "xl";
        this.modal.is_loading = true;
        let self = this;
        ApiCall(
          new DataManager({
            url: apiUrl(data.url),
            adaptor: new DrfAdaptorNew(),
            crossDomain: true,
          }),
          new Query(),
          function (e) {
            // self.pp = e.result;
            // console.log(e.result)
            self.modal.is_loading = false;
            self.modal.data = e.result;
          }
        );
      } else if (e_type == "C") {
        this.modal.is_close = true;
      } else if (e_type == "P") {
        this.modal.is_ddmp = true;
      } else if (e_type == "I") {
        this.modal.is_ddmi = true;
      }
    },
    getDates() {
      // console.log("showDate", this.showDate)
      let date_start, date_end;
      if (this.displayPeriod == "week") {
        date_start = addDays(this.showDate, -7);
        date_end = addDays(this.showDate, +7);
      } else {
        date_start = addDays(this.showDate, -45);
        date_end = addDays(this.showDate, +45);
      }
      // console.log(date_start.valueOf() , date_end.valueOf() )
      let self = this;
      self.loading = true;
      ApiCall(
        GetDataManagerNew("role_planningcalendar", [
          this.$store.state.role.id,
          date_start.valueOf(),
          date_end.valueOf(),
        ]),
        new Query(),
        function (e) {
          // self.pp = e.result;
          // console.log(e.result)
          self.events = e.result;
          self.loading = false;
        }
      );
    },
    setShowDate(d) {
      this.showDate = d;
      this.getDates();
    },
    onClickDay(d) {
      // this.message = `You clicked: ${d.toLocaleDateString()}`;
      console.log(`You clicked: ${d.toLocaleDateString()}`);
    },
    onClickEvent(e) {
      // this.message = `You clicked: ${e.title}`;
      // console.log(`You clicked event: ${e.startDate.toLocaleDateString()}`)
      // console.log(`You clicked event class: ${e.classes}`)
      // console.log(`You clicked event url: ${e.originalEvent.data}`)
      // console.log(`You clicked event type: ${e.originalEvent.e_type}`)
      // console.log(e)
      this.openModal(e.title, e.originalEvent.e_type, e.originalEvent.data);
    },
    onDrop(event, date) {
      this.message = `Moved: ${
        event.title || event.id
      } to ${date.toLocaleDateString()}`;
      // Determine the delta between the old start date and the date chosen,
      // and apply that delta to both the start and end date to move the event.
      const eLength = this.dayDiff(event.startDate, date);
      event.originalEvent.startDate = this.addDays(event.startDate, eLength);
      event.originalEvent.endDate = this.addDays(event.endDate, eLength);
    },
  },
};
</script>
